.update-monthly-mission-container {
  display: grid;
  gap: 16px;

  .ant-form {
    background: #fff;
    border: 1px solid #f2f2f2;
    border-radius: 12px;
    padding: 24px 16px 16px 16px;

    .ant-form-item-label {
      width: 100px;
    }

    .ant-select {
      min-width: 150px;
    }

    input {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      min-width: 150px;
    }

    .ant-picker-range {
      width: 100%;

      input {
        text-align: center;
        min-width: unset;
      }
    }

    .images {
      display: inline-flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 24px;

      .ant-form-item {
        position: relative;
        margin-bottom: unset;

        .ant-form-item-label {
          position: absolute;
          top: -28px;
          text-align: left;
        }
      }

      .control {
        display: flex;
        gap: 8px;
      }
    }

    .last-row .ant-form-item {
      margin-bottom: unset;
    }
  }

  .form-controller {
    display: flex;
    gap: 8px;
  }
}
