.update-achievement-container {
  display: grid;
  gap: 16px;

  .ant-form {
    background: #fff;
    border: 1px solid #f2f2f2;
    border-radius: 12px;
    padding: 24px 16px 16px 16px;

    .ant-form-item-label {
      width: 125px;
    }

    .ant-select {
      min-width: 150px;
    }

    input {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      min-width: 150px;
    }
  }

  .form-controller {
    display: flex;
    gap: 5px;
    margin-left: 100px;
  }
}
