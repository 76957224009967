.update-road-map-category-container {
  display: grid;
  gap: 16px;

  .form-input {
    input,
    .ant-select {
      width: 200px;
    }
    .ant-form-item {
      margin-bottom: 0;
    }
  }

  .choose-exam {
    display: flex;
    gap: 16px;

    .ant-form-item {
      margin-bottom: 0;

      input,
      .ant-select {
        width: 200px;
      }
    }
  }

  .ant-form .ant-form-item-label {
    width: 100px;
  }

  .control {
    display: flex;
    gap: 8px;
    position: absolute;
    width: 50px;
  }

  .time-option .ant-form-item {
    margin-bottom: 4px;
  }

  .ant-picker {
    width: 100%;

    input {
      text-align: center;
    }
  }

  .road-map-control {
    display: flex;
    gap: 8px;
    margin-left: 100px;
  }
}
