.update-road-map-container,
.edit-exam-branches-container {
  display: grid;
  gap: 16px;

  .form-input {
    input, .ant-select {
      width: 200px;
    }
    .ant-form-item {
      margin-bottom: 0;
    }
  }

  .choose-exam {
    display: flex;
    gap: 16px;

    .ant-form-item {
      margin-bottom: 0;

      input,
      .ant-select {
        width: 200px;
      }
    }
  }

  .ant-form .ant-form-item-label {
    width: 100px;
  }

  .control {
    display: flex;
    gap: 8px;
    position: absolute;
    width: 50px;
  }

  .time-option .ant-form-item {
    margin-bottom: 4px;
  }

  .ant-picker {
    width: 100%;

    input {
      text-align: center;
    }
  }

  .input-preview {
    position: relative;

    button {
      position: absolute;
      top: 0;
      right: 0;
      width: 16px;
      height: 32px;
    }
  }

  .question-container,
  .grade-container,
  .problem-set-container {
    .ant-descriptions-item-label {
      align-items: center;
      color: rgba(0, 0, 0, 0.88);
      place-content: flex-end;
      width: 100px;
    }
    .ant-descriptions-item-content {
      display: flex;
      flex-flow: wrap;
      gap: 16px;

      & > span {
        position: relative;
        background-color: #eeeeee;
        padding: 0px 16px 16px;
        color: #fff;
        border-radius: 8px;
      }

      .ant-form-item {
        margin: 0;
        width: 220px;
      }
      .ant-row {
        display: grid;

        .ant-col {
          text-align: left;

          label {
            font-size: 12px;
          }
        }
      }
    }
  }

  .question-container .control {
    right: -60px;
    top: 64px;
  }

  .problem-set-container .control {
    right: -60px;
    top: 128px;
  }

  .grade-container,
  .image-container {
    padding-bottom: 0 !important;

    .control {
      right: -50px;
      bottom: 0;
    }
  }

  .image-container {
    padding-bottom: 0 !important;
  }

  .road-map-control {
    display: flex;
    gap: 8px;
  }
}

.explain-container {
  margin-top: 20px;
  background: #fff;
  border: 1px solid #f2f2f2;
  border-radius: 12px;
  padding: 16px;

  .ant-descriptions-item {
    padding-bottom: 0 !important;
  }
  .ant-descriptions-item-label {
    align-items: center;
    color: rgba(0, 0, 0, 0.88);
    place-content: flex-end;
    width: 100px;
  }

  .list-icons {
    display: flex;
    gap: 16px;

    .icon-item {
      display: flex;
      align-items: center;
      gap: 4px;
    }
  }
}

.branch-container {
  .problem-set-filters-container {
    display: grid !important;
    gap: 16px;

    & > span {
      position: relative;
      padding: 0px 16px 16px;
      color: #fff;
      border-radius: 8px;
    }

    .ant-form-item {
      margin: 0;
      width: 215px;
    }
    .ant-row {
      display: grid;

      .ant-col {
        text-align: left;

        label {
          font-size: 12px;
        }
      }
    }
  }
}
