.tracking-container {
  .ant-form {
    .ant-form-item-label {
      text-align: left;
      width: 150px;
    }

    .ant-picker-input input {
      text-align: center;
    }
  }
}
