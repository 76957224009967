.daily-quiz-event-portal-container {
  display: grid;
  gap: 16px;

  .ant-form {
    .ant-form-item-label {
      width: 125px;
    }
    .ant-form-item-control-input {
      min-width: 150px;
    }

    .ant-picker {
      width: 100%;
    }
  }

  & > div {
    background: ghostwhite;
    border: 1px solid #f2f2f2;
    border-radius: 12px;
    padding: 16px;
  }
}
