.update-calibration-container {
  display: grid;

  .form-input {
    input,
    .ant-select {
      width: 200px;
    }
    .ant-form-item {
      .ant-form-item-label {
        padding: 0;
        width: 100px;
      }

      textarea {
        width: 300px;
      }
    }
  }

  .control {
    display: flex;
    gap: 8px;
    position: absolute;
    width: 50px;
  }

  .time-option .ant-form-item {
    margin-bottom: 4px;
  }

  .ant-picker {
    width: 100%;

    input {
      text-align: center;
    }
  }

  .input-preview {
    position: relative;

    button {
      position: absolute;
      top: 0;
      right: 0;
      width: 16px;
      height: 32px;
    }
  }

  .question-container,
  .grade-container,
  .problem-set-container {
    .ant-descriptions-item-label {
      align-items: center;
      color: rgba(0, 0, 0, 0.88);
      place-content: flex-end;
      width: 100px;
    }
    .ant-descriptions-item-content {
      display: flex;
      flex-flow: wrap;
      gap: 16px;

      & > span {
        position: relative;
        background-color: #eeeeee;
        padding: 0px 16px 16px;
        color: #fff;
        border-radius: 8px;
      }

      .ant-form-item {
        margin: 0;
        width: 220px;
      }
      .ant-row {
        display: grid;

        .ant-col {
          text-align: left;

          label {
            font-size: 12px;
          }
        }
      }
    }
  }

  .question-container .control {
    right: -60px;
    top: 64px;
  }

  .problem-set-container .control {
    right: -60px;
    top: 128px;
  }

  .grade-container,
  .image-container {
    padding-bottom: 0 !important;

    .control {
      right: -50px;
      bottom: 0;
    }
  }

  .image-container {
    padding-bottom: 0 !important;
  }

  .calibration-control {
    display: flex;
    gap: 8px;
    margin-left: 100px;
  }

  .question-sets-container {
    display: flex;
    text-align: center;
    max-width: 1000px;

    .question-set h4 {
      margin-left: 100px;
    }
  }
}
