.generate-vouchers-container {
  display: grid;
  gap: 16px;

  .ant-form {
    background: #fff;
    .ant-form-item-label {
      width: 125px;
    }

    .ant-picker {
      width: 100%;
    }
  }

  .form-controller {
    display: flex;
    gap: 5px;
    margin-left: 125px;
  }
}
