.update-quiz-container {
  display: grid;
  gap: 16px;

  .ant-form {
    background: #fff;
    border: 1px solid #f2f2f2;
    border-radius: 12px;
    padding: 24px 16px 16px 16px;

    .ant-form-item-label {
      width: 100px;
    }
  }

  .input-preview {
    position: relative;

    button {
      position: absolute;
      top: 0;
      right: -32px;
      width: 32px;
    }
  }

  .control {
    display: flex;
    gap: 8px;
    position: absolute;
    width: 50px;
    right: -80px;
    bottom: 24px;
  }

  .none-remove {
    right: -66px;
    width: 32px;
  }

  .form-controller {
    display: flex;
    gap: 5px;
    margin-left: 100px;
  }
}
