.update-story-container {
  display: grid;
  gap: 16px;

  .ant-form {
    background: #fff;
    border: 1px solid #f2f2f2;
    border-radius: 12px;
    padding: 24px 16px 16px 16px;
  }

  .ant-descriptions-item-label {
    align-items: center !important;
    justify-content: flex-end;
    width: 100px;
  }

  .ant-descriptions-item-content {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 16px;
    row-gap: 24px;

    .texts .latex-preview {
      left: calc(100% - 60px) !important;
    }

    .ant-select {
      width: 200px;
    }

    .ant-radio-group,
    .ant-checkbox-group {
      display: inline-flex;
      gap: 8px;
    }

    .ant-form-item {
      margin: unset !important;
      position: relative;

      .ant-form-item-label {
        top: -22px;
        position: absolute;
      }

      .ant-form-item-label > label {
        height: 100% !important;
        font-size: 12px;
      }

      input {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 200px;
      }

      .prefix-input-affix-wrapper {
        width: 200px;
      }

      .ant-input-wrapper {
        .ant-input {
          border-radius: 6px;
        }
        .ant-input-group-addon {
          background-color: transparent;
          border: none;
          padding-left: 16px;
        }
      }
    }

    .difficulty-wrapper {
      display: grid;
      gap: 32px;
    }

    .drag-solution {
      display: flex;
      gap: 16px;
    }
  }

  .list-slides {
    display: grid;
    row-gap: 16px;
    padding: 0 16px 16px;

    .slide-info {
      display: flex;
      gap: 16px;
    }

    .header span {
      font-size: 20px;
    }

    .prompt-wrapper .ant-descriptions-item-content {
      display: inline-grid;

      textarea {
        min-width: 200px;
        max-width: 750px;
      }
      .texts {
        max-width: 750px;
        display: inline-grid;
        row-gap: 24px;
      }

      .images {
        display: inline-flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 16px;
        row-gap: 24px;
      }
    }
  }

  .form-controller {
    display: flex;
    gap: 5px;
  }

  .upload-input {
    display: inline-block;
    margin-right: 5px;
  }

  .mixed-content {
    .text-image {
      display: grid;
      gap: 24px;

      .upload-input {
        margin-right: 0;
      }
    }

    .ant-descriptions-item-content {
      align-items: center !important;
    }
  }
}
