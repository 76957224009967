.custom-tag {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;

  .ant-tag {
    margin-inline-end: unset !important;
    padding-inline: unset !important;
  }

  .ant-tag-checkable {
    padding: 2px 10px !important;

    &:hover {
      color: unset !important;
      background-color: unset !important;
    }
  }

  .ant-tag-checkable-checked {
    background-color: unset !important;

    &:hover {
      color: unset !important;
    }
  }

  .tag-unchecked {
    background-color: #f0f0f0 !important;

    &:hover {
      cursor: pointer;

      .ant-tag-checkable {
        color: #1677ff !important;
      }
    }
  }

  .tag-checked {
    background-color: #1677ff !important;

    &:hover {
      cursor: pointer;

      .ant-tag-checkable {
        color: rgba(255, 255, 255, 0.6) !important;
      }
    }

    .ant-tag-checkable-checked {
      color: #fff !important;
      border: 1px solid #1677ff !important;
    }
  }
}
