.update-account-user-container {
  display: grid;
  gap: 16px;

  > div {
    background: #fff;
    border: 1px solid #f2f2f2;
    border-radius: 12px;
  }
  .ant-form {
    .ant-form-item-label {
      width: 125px;
    }

    .ant-picker {
      width: 100%;
    }

    .ant-input-disabled {
      color: rgba(0, 0, 0, 0.5);
    }
  }

  .form-controller {
    display: flex;
    gap: 5px;
    margin-left: 125px;
  }

  .pic-form {
    .ant-form-item-label {
      width: 125px;
    }
    .form-controller {
      margin-left: 125px;
    }
  }

  .ant-switch.ant-switch-checked:hover,
  .ant-switch.ant-switch-checked {
    background-color: rgb(42, 187, 42);
  }
}
