.account-admin-portal-container {
  display: grid;
  gap: 16px;

  & > div {
    background: ghostwhite;
    border: 1px solid #f2f2f2;
    border-radius: 12px;
    padding: 16px;
  }
}
