.account-user-portal-container {
  display: grid;
  gap: 16px;

  .ant-form-item-label {
    width: 175px;
  }

  .ant-picker {
    width: 100%;
  }

  .filter-controller {
    margin-left: 175px;
  }
}
