.update-exam-container {
  display: grid;
  gap: 16px;

  .ant-form {
    background: #fff;
    border: 1px solid #f2f2f2;
    border-radius: 12px;
    padding: 24px 16px 16px 16px;

    .ant-form-item-label {
      width: 100px;
    }
  }

  .control {
    display: flex;
    gap: 8px;
    position: absolute;
    width: 50px;
  }

  .time-option .ant-form-item {
    margin-bottom: 4px;
  }

  .ant-picker {
    width: 100%;

    input {
      text-align: center;
    }
  }

  .ant-form-item-control-input {
    width: 250px;
  }

  .input-preview {
    position: relative;

    button {
      position: absolute;
      top: 0;
      right: 0;
      width: 16px;
      height: 32px;
    }
  }

  .question-container,
  .grade-container,
  .image-container {
    .ant-descriptions-item-label {
      align-items: center;
      color: rgba(0, 0, 0, 0.88);
      place-content: flex-end;
      width: 100px;
      margin-top: 32px;
    }
    .ant-descriptions-item-content {
      display: flex;
      flex-flow: wrap;
      gap: 8px;

      .ant-form-item {
        margin: 0;
        width: 250px;
      }
      .ant-row {
        display: grid;

        .ant-col {
          text-align: left;

          label {
            font-size: 12px;
          }
        }
      }
    }
  }

  .question-container .control {
    right: -50px;
    top: 96px;
  }

  .mobile-image {
    padding-bottom: 0 !important;

    .ant-form-item {
      margin-top: 24px;
      margin-bottom: 0;
    }
  }

  .grade-container,
  .image-container {
    padding-bottom: 0 !important;

    .control {
      right: -50px;
      bottom: 0;
    }
  }

  .image-container {
    padding-bottom: 0 !important;
  }

  .form-controller {
    display: flex;
    gap: 5px;
    margin-left: 100px;
  }
}

.explain-container {
  margin-top: 20px;
  background: #fff;
  border: 1px solid #f2f2f2;
  border-radius: 12px;
  padding: 16px;

  .ant-descriptions-item {
    padding-bottom: 0 !important;
  }
  .ant-descriptions-item-label {
    align-items: center;
    color: rgba(0, 0, 0, 0.88);
    place-content: flex-end;
    width: 100px;
  }

  .list-icons {
    display: flex;
    gap: 16px;

    .icon-item {
      display: flex;
      align-items: center;
      gap: 4px;
    }
  }
}
