.story-portal-container {
  display: grid;
  gap: 16px;

  & > div {
    background: ghostwhite;
    border: 1px solid #f2f2f2;
    border-radius: 12px;
    padding: 16px;
  }

  .create-upload button,
  .table-control button {
    width: 160px;
  }
}

.upload-modal {
  .ant-upload-list {
    max-height: 300px;
    overflow-y: auto;
  }

  .ant-input-group-wrapper {
    margin-bottom: 10px;
  }
}

.upload-error-modal {
  width: 600px !important;
  .ant-modal-confirm-content {
    display: grid;
    row-gap: 8px;
  }

  .upload-item {
    &.error {
      color: #ff3333;
    }
    &.success {
      color: #28a745;
    }
  }
}
