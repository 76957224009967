body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

html,
body,
.App,
#root {
  height: 100%;
}

/* Style scroll bar start */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Style cho dải nền của thanh cuộn */
::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

/* Style cho thanh cuộn khi trượt chuột qua */
::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 5px;
}

/* Style cho nút nhấn trên thanh cuộn */
::-webkit-scrollbar-button {
  display: none;
}
/* Style scroll bar end */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.none-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
  supported by Chrome, Edge, Opera and Firefox */
}

.portal {
  display: flex;
  flex-direction: column;
  gap: 16px;

  & > div {
    background: ghostwhite;
    border: 1px solid #f2f2f2;
    border-radius: 12px;
    padding: 16px;
  }
}
