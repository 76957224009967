.question {
  form {
    display: inline-flex;
    flex-wrap: wrap;

    .ant-row {
      width: 100%;
    }

    .ant-form-item {
      .ant-form-item-label {
        width: 125px;
      }
    }

    .filter-controller {
      margin: 0;

      .ant-form-item-control-input-content {
        display: flex;
        gap: 16px;
        width: 100%;
      }
    }
  }
}
