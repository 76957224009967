.loading-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.loading-circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #3f51b5;
  margin: 10px;
  animation: pulse 1s ease-in-out infinite;
}

.loading-circle:nth-of-type(2) {
  width: 30px;
  height: 30px;
  animation-delay: -0.5s;
}

.loading-circle:nth-of-type(3) {
  width: 40px;
  height: 40px;
  animation-delay: -1s;
}

@keyframes pulse {
  0% {
    transform: scale(0.8);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.8);
  }
}

.overlay {
  position: absolute;
  width: calc(100vw);
  height: calc(100vh - 100px);
  background: #ffffff;
  opacity: 0.75;
  z-index: 10000;
}
