.collapse {
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 4px;
  position: relative;

  .header {
    align-items: center;
    background-color: rgba(0, 0, 0, 0.03);
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    display: flex;
    justify-content: space-between;
    padding: 8px;
    cursor: pointer;
  }

  .controller {
    display: flex;
    gap: 5px;
    position: absolute;
    right: 40px;
    top: 8px;
  }

  .header h2 {
    margin: 0;
  }

  .icon {
    width: 16px;
    height: 16px;
    border: 1px solid #ccc;
    border-radius: 50%;
    transition: transform 0.3s ease;
  }

  .icon--open {
    transform: rotate(180deg);
  }

  .content {
    padding: 24px 16px 16px;
  }
}
