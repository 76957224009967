.update-question {
  background: #fff;
  border: 1px solid #f2f2f2;
  border-radius: 12px;
  padding: 24px 16px;

  .ant-descriptions-item {
    padding-bottom: 24px !important;
  }

  .ant-descriptions-item-label {
    align-items: center !important;
    justify-content: flex-end;
    width: 150px;
  }

  .ant-descriptions-item-content {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 16px;
    row-gap: 24px;

    .ant-select {
      width: 200px;
    }

    .ant-radio-group,
    .ant-checkbox-group {
      display: inline-flex;
      gap: 8px;
    }

    .ant-form-item {
      margin: unset !important;
      position: relative;

      .ant-form-item-label {
        top: -22px;
        position: absolute;
      }

      .ant-form-item-label > label {
        height: 100% !important;
        font-size: 12px;
      }

      input {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 200px;
      }

      .ant-input-wrapper {
        .ant-input {
          border-radius: 6px;
        }
        .ant-input-group-addon {
          background-color: transparent;
          border: none;
          padding-left: 16px;
        }
      }

      .ant-form-item-control-input-content {
        .upload-input,
        .prefix-input-affix-wrapper {
          width: 200px;
        }
      }
    }

    .difficulty-wrapper {
      display: grid;
      gap: 32px;
    }

    .drag-solution {
      display: flex;
      gap: 16px;
    }
  }

  .filter-wrapper .ant-descriptions-item-content {
    display: grid !important;
    gap: 32px;

    & > div {
      gap: 16px;
    }

    .filter-control {
      display: flex;
      gap: 16px;
    }
  }

  .prompt-wrapper .ant-descriptions-item-content,
  .question-wrapper .ant-descriptions-item-content,
  .explain-wrapper .ant-descriptions-item-content {
    display: inline-grid;

    .latex-preview {
      left: 700px !important;
    }

    textarea {
      max-width: 750px;
    }
    .texts {
      max-width: 750px;
      display: inline-grid;
      row-gap: 24px;
    }

    .images {
      display: inline-flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 16px;
    }
  }

  .explain-wrapper .ant-descriptions-item-content {
    .texts {
      position: relative;
      width: calc(100% - 66px);

      .control {
        display: flex;
        gap: 16px;
        position: absolute;
        width: 50px;
        right: -66px;
        bottom: 0;
      }
    }
    .images .control {
      display: flex;
      gap: 16px;
    }
  }

  .explain-wrapper,
  .note-text-save {
    padding-bottom: 0px !important;
  }

  .form-controller {
    display: inline-flex;
    gap: 8px;
    margin-left: 150px;
  }
}
